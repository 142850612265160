import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5a5478e3 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _17cdc198 = () => interopDefault(import('../pages/career.vue' /* webpackChunkName: "pages/career" */))
const _fc83675e = () => interopDefault(import('../pages/career/_position.vue' /* webpackChunkName: "pages/career/_position" */))
const _1996a076 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _5680cf5a = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _ff6bf29e = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _ce5a6026 = () => interopDefault(import('../pages/selected-works/index.vue' /* webpackChunkName: "pages/selected-works/index" */))
const _33987c10 = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _578d7eba = () => interopDefault(import('../pages/team/index.vue' /* webpackChunkName: "pages/team/index" */))
const _5f252fea = () => interopDefault(import('../pages/selected-works/_work.vue' /* webpackChunkName: "pages/selected-works/_work" */))
const _ec1183be = () => interopDefault(import('../pages/team/_member.vue' /* webpackChunkName: "pages/team/_member" */))
const _7a0c53a8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _5a5478e3,
    name: "about"
  }, {
    path: "/career",
    component: _17cdc198,
    name: "career",
    children: [{
      path: ":position?",
      component: _fc83675e,
      name: "career-position"
    }]
  }, {
    path: "/contact",
    component: _1996a076,
    name: "contact"
  }, {
    path: "/newsletter",
    component: _5680cf5a,
    name: "newsletter"
  }, {
    path: "/privacy-policy",
    component: _ff6bf29e,
    name: "privacy-policy"
  }, {
    path: "/selected-works",
    component: _ce5a6026,
    name: "selected-works"
  }, {
    path: "/services",
    component: _33987c10,
    name: "services"
  }, {
    path: "/team",
    component: _578d7eba,
    name: "team"
  }, {
    path: "/selected-works/:work?",
    component: _5f252fea,
    name: "selected-works-work"
  }, {
    path: "/team/:member",
    component: _ec1183be,
    name: "team-member"
  }, {
    path: "/",
    component: _7a0c53a8,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
